import React from "react"
import { graphql } from 'gatsby'
import Header from "../components/Header/header"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import CtaBanner from "../components/CtaBanner/CtaBanner"

import lowLoader from "../../static/img/low-loader-1.jpg"
import lowLoaderNine from "../../static/img/low-loader-9.jpg"
import lowLoaderFour from "../../static/img/low-loader-4.jpg"
import lowLoaderTwo from "../../static/img/low-loader-2.jpg"
import machineInstall from "../../static/img/machine-installation-3.jpg"
import machineInstallFive from "../../static/img/machine-installation-5.jpg"
import steelErecting from "../../static/img/steel-erecting-and-cladding-5.jpg"
import scaniaR500 from "../../static/img/scania-r500-2.jpg"
import versaLift from "../../static/img/versa-lift-60-80.jpg"
import versaLiftSix from "../../static/img/versa-lift-6.jpg"
import steelErectingThree from "../../static/img/steel-erecting-and-cladding-3.jpg"
import steelErectingEleven from "../../static/img/steel-erecting-and-cladding-11.jpg"

import "../assets/sass/theme/pages/our-fleet.scss"

class FleetPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: fleetitems } = data.allMarkdownRemark
    // console.log(fleetitems)
    return (
      <Layout>
        <Header isHeaderSolid />
        <SEO
          title="Our Fleet"
          description="Our wide range of vehicles are suitable for a variety of jobs. We specialise in the installation, removal & relocation of industrial, manufacturing and processing machinery and equipment."
          url="our-fleet"
        />
        <main className="our-fleet with-spacing">
          <div className="page-width">
            <div className="our-fleet__inner">
              <div className="our-fleet__inner_left">
                <h1 className="title">Our Fleet</h1>
                <p className="lead-paragraph">CNR Engineering's fleet consists of a wide range of vehicles suitable for a variety of jobs.</p>
                {fleetitems &&
                  fleetitems.map(({ node: fleet }) => (
                    fleet.frontmatter.displayonsite &&
                    <li key={fleet.id}>
                      {fleet.frontmatter.description}
                    </li>
                  ))
                }
              </div>
              <div className="our-fleet__inner_right">
                <div className="grid-fleet">
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${lowLoader}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${machineInstall}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item" style={{ backgroundImage: `url('${steelErecting}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${scaniaR500}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${versaLift}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${steelErectingEleven}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${steelErectingThree}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${lowLoaderFour}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${machineInstallFive}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${lowLoaderNine}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${versaLiftSix}')`, backgroundPosition: `center center` }}></div>
                  <div className="grid-fleet__item grid-fleet__item--mobile" style={{ backgroundImage: `url('${lowLoaderTwo}')`, backgroundPosition: `center center` }}></div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <CtaBanner ctaText="Get More Information About Our Fleet" />
      </Layout>
    )
  }
}

export default FleetPage

export const pageQuery = graphql`
  query FleetFilter {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "fleet-child-page"}}}, sort: {fields: frontmatter___sortorder}) {
      edges {
        node {
          id
          frontmatter {
            description
            displayonsite
            description
            tags
            templateKey
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
